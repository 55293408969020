import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function ItsmeOverview() {
  return (
    <Grid container>
      <Grid item xs={12} lg={8} style={{ margin: '0 auto' }}>
        Hello, it's me.
      </Grid>
    </Grid>
  );
}
